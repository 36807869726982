/* eslint-disable consistent-return */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CopyContainer } from './CopyButton.style'
import { CommonTranslations, Translations } from '../../../config'

interface Props {
  children: JSX.Element
  value: string
}

export function CopyButton({ children, value }: Props) {
  /* Translations */
  const { t } = useTranslation([Translations.COMMON])

  /* Handle copied */
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      const copying = await navigator.clipboard.writeText(text)
      return copying
    }
  }

  const handleCopyClick = (textToCopy: string) => {
    const TIME_TO_DELAY_MSG = 1000
    copyTextToClipboard(textToCopy ?? '').then(() => {
      setIsCopied(true)

      setTimeout(() => {
        setIsCopied(false)
      }, TIME_TO_DELAY_MSG)
    })
  }

  return (
    <CopyContainer
      copied={isCopied}
      onClick={() => handleCopyClick(value)}
      data-tooltip={t(CommonTranslations.COPIED)}
    >
      {children}
    </CopyContainer>
  )
}
