import React from 'react'

import { icons } from '../../../../images'

import { FeedbackMsgContainer, FeedbackMsgIcon } from './FeedbackMsg.style'

interface Props {
  msg: string
}

export function FeedbackMsg({ msg }: Props) {
  return (
    <FeedbackMsgContainer>
      <FeedbackMsgIcon src={icons.alertFeedback} />
      {msg}
    </FeedbackMsgContainer>
  )
}
