import { css, styled } from '@mui/material'

export const PaginationWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto 0;
  `,
)

export const GnomesList = styled('div')(
  () => css`
    width: 100%;
    max-width: 1140px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
    flex-flow: row wrap;
    gap: 16px;
    margin: 0 auto;
  `,
)

export const GnomeSortWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  `,
)

export const PanelInventory = styled('span')(
  () => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
)

export const TokenSymbol = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral60};
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.24px;
  `,
)

export const TokenLogo = styled('img')(
  () => css`
    width: 2rem;
    height: auto;
    flex: 0 0 auto;
  `,
)

export const PanelBalance = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
)

export const PanelTitle = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral40};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 24px;
  `,
)

export const InventoryPanel = styled('div')(
  ({ theme }) => css`
    width: 100%;
    height: 100px;
    max-height: 120px;
    box-sizing: border-box;
    padding: 1.5rem 2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    flex-flow: column;
    background: ${theme.palette.font.neutral05};
    box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
  `,
)

export const InventoryWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  `,
)

export const ContentContainer = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 40px;
  `,
)

export const TeamContainer = styled('div')(() => ({
  width: '100%',
  overflowX: 'hidden',
  padding: '0px 50px 50px 50px',
  boxSizing: 'border-box',
}))
