import React from 'react'

import { commons } from '../../../../../../images'
import { GnomeType } from '../../../../../../commons/types'
import { GnomeTag } from '../../../../../../commons/components/GnomeTag/GnomeTag'

import {
  CardImage,
  CardShadow,
  GnomeCardFooter,
  GnomeId,
  ModalGnomeCardContainer,
} from './ModalGnomeCard.style'

/* interface ModalGnomeCardProps {
  gnome: Gnome
} */

export function ModalGnomeCard() {
  return (
    <ModalGnomeCardContainer>
      <CardImage src={commons.gnomePreview} />
      <CardShadow />

      <GnomeCardFooter>
        <GnomeId>#8176</GnomeId>

        <GnomeTag gnomeType={GnomeType.Unknow} />
      </GnomeCardFooter>
    </ModalGnomeCardContainer>
  )
}
