import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, TOKEN_SYMBOL, Translations } from '../../config'
import { useWalletContext } from '../../commons/contexts/WalletContext'

import { commons, icons } from '../../images'
import { ButtonValues, GnomesAdapted } from '../../commons/types'
import { GnomeId } from '../../commons/components/GnomeCardNew/GnomeCardNew.style'
import { CommonButton } from '../../commons/components/CommonButton/CommonButton'
import { GnomeTag } from '../../commons/components/GnomeTag/GnomeTag'
import { AddressShorten } from '../../commons/components/AddressShorten/AddressShorten'
import { UpLevelTag } from '../../commons/components/GnomeCardNew/Components/UpLevelTag'
import { ModalCommon } from '../../commons/components/ModalCommon/ModalCommon'
import { SellGnomeModal } from '../GnomesTeam/Components/SellGnomeModal/SellGnomeModal'
import { ModalSuccessTsx } from '../../commons/components/ModalCommon/Modals/ModalSuccessTsx'
import { FeedbackMsg } from '../GnomesTeam/Components/FeedbackMsg/FeedbackMsg'

import {
  AddressShortenWapper,
  ArrowIcon,
  BackButtonWrapper,
  DetailLeft,
  DetailRight,
  GnomeDetailsContainer,
  GnomeDetailsWrapper,
  GnomeImage,
  GnomeInfoWrapper,
  InfoTagContent,
  InfoTagLabel,
  InfoTokenSymbol,
  OwnerTitle,
  OwnerWrapper,
  PriceWrapper,
  TagExp,
  TagsWrapper,
} from './GnomeDetails.style'

interface GnomeDetailsProps {
  gnome: GnomesAdapted | null
  forSale: boolean
  backAction: () => void
}

export function GnomeDetails({
  gnome,
  backAction,
  forSale,
}: GnomeDetailsProps) {
  /* Translations */
  const { t } = useTranslation([Translations.COMMON])

  /* Wallet context */
  const {
    address,
    buyMarketplaceGnome,
    removeItemFromMarketplace,
    removeDetailsGnome,
  } = useWalletContext()

  /* Handle success modal */
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  /* const handleShowSuccessModal = () => setShowSuccess(true) */

  const handleCloseSuccessModal = useCallback(() => {
    setShowSuccess(false)
    removeDetailsGnome()
  }, [removeDetailsGnome])

  /* Handle sell modal */
  const [showSellModal, setShowSellModal] = useState<boolean>(false)
  const handleShowSellModal = () => setShowSellModal(true)
  const handleCloseSellModal = () => setShowSellModal(false)

  /* Handle cancel sale */
  const [cancellingSale, setCancellingSale] = useState<boolean>(false)
  const handleCancelSale = useCallback(async () => {
    if (!gnome) return
    setCancellingSale(true)

    try {
      await removeItemFromMarketplace(gnome?.id?.toString())
    } catch (error) {
      setCancellingSale(false)
      handleCloseSuccessModal()
    } finally {
      setCancellingSale(false)
    }
  }, [gnome, removeItemFromMarketplace, handleCloseSuccessModal])

  /* Handle buy gnome */
  const [buyingGnome, setBuyingGnome] = useState<boolean>(false)
  const handleBuyGnome = useCallback(async () => {
    if (!gnome) return
    setBuyingGnome(true)

    try {
      await buyMarketplaceGnome(
        gnome?.id?.toString(),
        gnome?.metadata?.price_lac?.toString() ?? '0',
      )
    } catch (error) {
      setBuyingGnome(false)
    } finally {
      setBuyingGnome(false)
    }
  }, [buyMarketplaceGnome, gnome])

  /* UI Handler */
  const myGnome = gnome?.owner === `${address}`

  const textButton = (): string => {
    if (myGnome && !forSale) return t(CommonTranslations.SELL)
    if (myGnome && forSale) return t(CommonTranslations.CANCEL_SELL)
    if (!myGnome) return t(CommonTranslations.BUY)

    return t(CommonTranslations.BUY)
  }

  const typeButton = (): ButtonValues => {
    if (myGnome && forSale) return ButtonValues.Danger

    return ButtonValues.Primary
  }

  /* Handle main button action */
  // eslint-disable-next-line @typescript-eslint/no-shadow, consistent-return
  const handleMainAction = (gnome: GnomesAdapted) => {
    if (!gnome) return false

    if (myGnome && forSale) return handleCancelSale()
    if (myGnome && !forSale) return handleShowSellModal()
    if (!myGnome) return handleBuyGnome()
  }

  if (!gnome) return null

  return (
    <>
      <GnomeDetailsContainer>
        <BackButtonWrapper onClick={backAction}>
          <ArrowIcon src={icons.leftArrow} />
        </BackButtonWrapper>

        <GnomeDetailsWrapper>
          <DetailLeft>
            <GnomeImage src={gnome?.metadata?.image} />
          </DetailLeft>

          <DetailRight>
            <GnomeInfoWrapper>
              <GnomeId>{`#${gnome?.id}`}</GnomeId>

              <TagsWrapper>
                <GnomeTag gnomeType={gnome?.metadata?.gnome_type} />

                <TagExp>{`LV ${gnome?.level}`}</TagExp>

                <TagExp>{`XP ${gnome?.xp}`}</TagExp>

                {gnome?.can_level_up && myGnome && <UpLevelTag />}
              </TagsWrapper>

              <OwnerWrapper>
                <OwnerTitle>{t(CommonTranslations.OWNER)}</OwnerTitle>

                <AddressShortenWapper>
                  <AddressShorten address={gnome?.owner} />
                </AddressShortenWapper>
              </OwnerWrapper>

              {!myGnome && (
                <PriceWrapper>
                  <OwnerTitle>{t(CommonTranslations.CURRENT_PRICE)}</OwnerTitle>

                  <InfoTagContent>
                    <InfoTokenSymbol src={commons.lacSymbol} />
                    <InfoTagLabel>{`${gnome?.metadata?.price_lac} ${TOKEN_SYMBOL}`}</InfoTagLabel>
                  </InfoTagContent>
                </PriceWrapper>
              )}

              <CommonButton
                variant={typeButton()}
                text={textButton()}
                clickHandler={() => handleMainAction(gnome)}
                disabled={buyingGnome || cancellingSale}
                full
              />

              {(cancellingSale || buyingGnome) && (
                <FeedbackMsg msg={t(CommonTranslations.AWAITING_TSX)} />
              )}
            </GnomeInfoWrapper>
          </DetailRight>
        </GnomeDetailsWrapper>
      </GnomeDetailsContainer>

      {/* Sell Gnome modal */}
      <ModalCommon isShown={showSellModal} closeModal={handleCloseSellModal}>
        <SellGnomeModal gnome={gnome} closeModal={handleCloseSellModal} />
      </ModalCommon>

      {/* Success tsx Modal */}
      <ModalCommon isShown={showSuccess} closeModal={handleCloseSuccessModal}>
        <ModalSuccessTsx clickHandler={handleCloseSuccessModal} />
      </ModalCommon>
    </>
  )
}
