import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Pagination } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useWalletContext } from '../../commons/contexts/WalletContext'
import { LIMIT_GNOMES_REQUEST, TOKEN_SYMBOL } from '../../config'
import { RoutesEnum } from '../../routes/constants'
import { GnomesAdapted } from '../../commons/types'
import {
  CommonTranslations,
  SnackbarTranslations,
  Translations,
} from '../../config/i18n/i18n'
import { GnomesFiltered } from './utils'
import useGetBalance from '../../commons/hooks/useGetBalance'
import { fetchActions, fetchGnomes } from '../../services/backend'
import { useAppContext } from '../../commons/contexts/AppContext'
import { Action } from '../Inventory/models/Item'

import { DashboardAppBar } from '../../commons/components/DashboardAppBar'
import { CommonTitle } from '../../commons/components/CommonTitle/CommonTitle'
import { GnomeCardNew } from '../../commons/components/GnomeCardNew'
import { commons } from '../../images'
import { SelectSort } from '../../commons/components/SelectSort/SelectSort'
import { CommonButton } from '../../commons/components/CommonButton/CommonButton'
import { GnomeDetails } from '../GnomeDetails'
import { GnomeAdapter } from './utils/GnomeAdapter'

import {
  TeamContainer,
  ContentContainer,
  GnomesList,
  GnomeSortWrapper,
  InventoryWrapper,
  InventoryPanel,
  PanelTitle,
  PanelBalance,
  TokenSymbol,
  TokenLogo,
  PanelInventory,
  PaginationWrapper,
} from './GnomesTeam.style'

export function GnomesTeam() {
  /* Wallet context */
  const { address, selectedGnome, setDetailsGnome, removeDetailsGnome } =
    useWalletContext()

  /* Session */
  const { session } = useAppContext()

  /* Balance hook */
  const { balance } = useGetBalance()

  /* Translations */
  const { t } = useTranslation([Translations.COMMON])
  const { enqueueSnackbar } = useSnackbar()

  /* Fetch gnomes handler */
  const [gnomes, setGnomes] = useState<GnomesAdapted[] | []>([])
  const [pages, setPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const INITIAL_PAGE = 1

  const getGnomes = useCallback(
    async (page: number) => {
      try {
        if (!address) return
        const data = await fetchGnomes({
          owner: address,
          limit: LIMIT_GNOMES_REQUEST,
          offset: (page - INITIAL_PAGE) * LIMIT_GNOMES_REQUEST,
        })
        const adapt = await GnomeAdapter({ gnomes: data.results })
        setGnomes(adapt)

        const totalPages = Math.ceil(data.count / LIMIT_GNOMES_REQUEST)
        setPages(totalPages)
      } catch (err) {
        enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_RETRIEVE_TOKENS), {
          variant: 'error',
        })
      }
    },
    [address, enqueueSnackbar, t],
  )

  useEffect(() => {
    getGnomes(INITIAL_PAGE)
  }, [getGnomes])

  const handleChange = (_: unknown, page: number) => {
    setCurrentPage(page)
    getGnomes(page)
  }

  const handleReloadGnomes = () => getGnomes(currentPage)

  /* Handle sort gnomes list */
  const [param, setParam] = useState<string | null>(null)
  const gnomesFiltered = GnomesFiltered({
    gnomesArr: gnomes,
    filter: param,
  })

  /* Handle Actions */
  const [actions, setActions] = useState<Action[] | null>(null)

  const getActions = useCallback(async () => {
    try {
      const data = await fetchActions(session ?? '')
      setActions(data.results)
    } catch (err) {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_RETRIEVE_ITEMS), {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, session, t])

  useEffect(() => {
    getActions()
  }, [getActions])

  return (
    <TeamContainer>
      <DashboardAppBar />

      <ContentContainer>
        {!selectedGnome && (
          <>
            <CommonTitle title={`${t(CommonTranslations.GNOMES_TEAM_TITLE)}`} />

            <InventoryWrapper>
              <InventoryPanel>
                <PanelTitle>
                  {t(CommonTranslations.PANEL_INVENTORY_BALANCE)}
                </PanelTitle>

                <PanelBalance>
                  <TokenLogo src={commons.lacSymbol} />
                  <TokenSymbol>{`${balance} ${TOKEN_SYMBOL}`}</TokenSymbol>
                </PanelBalance>
              </InventoryPanel>

              <InventoryPanel>
                <PanelTitle>
                  {t(CommonTranslations.PANEL_INVENTORY_MUSHROOMS)}
                </PanelTitle>

                <PanelInventory>
                  <TokenSymbol>{actions?.length}</TokenSymbol>
                  <CommonButton
                    text={t(CommonTranslations.GO_TO_INVENTORY)}
                    small
                    to={RoutesEnum.INVENTORY}
                  />
                </PanelInventory>
              </InventoryPanel>
            </InventoryWrapper>

            <GnomeSortWrapper>
              <CommonTitle title={`${gnomes?.length} gnomos`} subtitle />
              <SelectSort
                onFilter={filter => setParam(filter)}
                defaultOptions
                disabled={!gnomes.length}
              />
            </GnomeSortWrapper>

            <GnomesList>
              {param
                ? gnomesFiltered?.map(gnome => (
                    <GnomeCardNew
                      key={gnome?.id}
                      gnome={gnome}
                      showGnome={() => setDetailsGnome(gnome)}
                      reloadGnomes={handleReloadGnomes}
                      forSale={false}
                      hasItemToLvlUp={!!actions?.length}
                    />
                  ))
                : gnomes?.map(gnome => (
                    <GnomeCardNew
                      key={gnome?.id}
                      gnome={gnome}
                      showGnome={() => setDetailsGnome(gnome)}
                      reloadGnomes={handleReloadGnomes}
                      forSale={false}
                      hasItemToLvlUp={!!actions?.length}
                    />
                  ))}
            </GnomesList>

            <PaginationWrapper>
              <Pagination
                count={pages}
                page={currentPage}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </PaginationWrapper>
          </>
        )}

        {selectedGnome && (
          <GnomeDetails
            gnome={selectedGnome}
            backAction={removeDetailsGnome}
            forSale={false}
          />
        )}
      </ContentContainer>
    </TeamContainer>
  )
}
