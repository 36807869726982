import { Gnome, GnomesAdapted } from '../../../commons/types'
import { GetAttGnome } from './GetAttGnome'
import { GetGnomeTier } from './GetGnomeTier'

interface Props {
  gnomes: Gnome[]
  price?: string
  owner?: string
}

export function GnomeAdapter({
  gnomes,
  price,
  owner,
}: Props): GnomesAdapted[] | [] {
  if (!gnomes || typeof gnomes !== 'object') return []

  const adaptedGnomes = gnomes?.map((gnome: Gnome) => ({
    id: gnome?.id,
    owner: owner ?? gnome?.owner,
    can_level_up: gnome?.can_level_up,
    level: GetAttGnome({
      attrs: gnome?.metadata?.attributes,
      attTofind: 'level',
    }),
    xp: GetAttGnome({ attrs: gnome?.metadata?.attributes, attTofind: 'xp' }),
    metadata: {
      image: gnome?.metadata?.image ?? '',
      face_image: gnome?.metadata?.face_image ?? '',
      gnome_type: GetGnomeTier({ attrs: gnome?.metadata?.attributes }),
      price_lac: price ?? gnome?.metadata?.price_lac ?? '0',
      attributes: gnome?.metadata?.attributes,
    },
  }))

  return adaptedGnomes
}
