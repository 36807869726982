import React, { useCallback, useState } from 'react'
import { parseUnits } from 'ethers'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, Translations } from '../../../../config/i18n/i18n'
import { TOKEN_SYMBOL } from '../../../../config'
import { GnomesAdapted } from '../../../../commons/types'

import { CommonButton } from '../../../../commons/components/CommonButton/CommonButton'
import { GnomeTag } from '../../../../commons/components/GnomeTag/GnomeTag'
import { useWalletContext } from '../../../../commons/contexts/WalletContext'

import {
  GnomeInfoWrapper,
  ImageGnome,
  ImageId,
  ImageInfo,
  ImageOver,
  ImageWrapper,
  InfoDesc,
  InfoTitle,
  InfoWrapper,
  InputWrapper,
  LabelInput,
  PriceInput,
  SellGnomeModalContainer,
} from './SellGnomeModal.style'

interface SellGnomeModalProps {
  gnome: GnomesAdapted | null
  closeModal: () => void
}

export function SellGnomeModal({ gnome, closeModal }: SellGnomeModalProps) {
  /* Translate */
  const { t } = useTranslation([Translations.COMMON])

  /* Handler confirm button behavior */
  const [price, setPrice] = useState<number | string>('')
  const handleOnChange = (priceValue: string) => {
    setPrice(priceValue)
  }

  /* Handler Sell event */
  const { listItemToMarketplace } = useWalletContext()

  const [selling, setSelling] = useState<boolean>(false)

  const handleSell = useCallback(async () => {
    if (!gnome || !price) return

    const weiPrice = parseUnits(price?.toString(), 'ether').toString()
    const weiPriceBN = new BigNumber(weiPrice)
    const isValidPrice = !weiPriceBN.isZero()

    setSelling(true)
    if (isValidPrice) {
      try {
        await listItemToMarketplace(gnome?.id?.toString(), weiPriceBN)
        closeModal()
      } catch (error) {
        setSelling(false)
      }
    }
  }, [gnome, price, listItemToMarketplace, closeModal])

  if (!gnome) return null

  return (
    <SellGnomeModalContainer>
      <GnomeInfoWrapper>
        <ImageWrapper>
          <ImageOver />
          <ImageGnome src={gnome?.metadata?.image} />
          <ImageInfo>
            <ImageId>{`#${gnome.id}`}</ImageId>

            <GnomeTag gnomeType={gnome.metadata.gnome_type} />
          </ImageInfo>
        </ImageWrapper>

        <InfoWrapper>
          <InfoTitle>{`${t(CommonTranslations.SELL)} Gnomo`}</InfoTitle>
          <InfoDesc>{t(CommonTranslations.SELL_DESC)}</InfoDesc>

          <InputWrapper>
            <LabelInput>
              {`${t(CommonTranslations.TOTAL_IN)} ${TOKEN_SYMBOL}`}
            </LabelInput>

            <PriceInput
              onChange={e => handleOnChange(e.target.value)}
              value={`${price}`}
              type="number"
              min={0.0001}
              placeholder={t(CommonTranslations.PRICE) as string}
            />

            {/* TO-DO Implement feedback msg on errors */}
            {/* <FeedbackMsg msg="FeedbackMsg" /> */}
          </InputWrapper>
        </InfoWrapper>
      </GnomeInfoWrapper>

      <CommonButton
        text={
          selling
            ? t(CommonTranslations.CONFIRMING)
            : t(CommonTranslations.CONFIRM)
        }
        clickHandler={handleSell}
        disabled={!price || selling || price <= '0'}
      />
    </SellGnomeModalContainer>
  )
}
