import { AttributesEntity, GnomeType } from '../../../commons/types'

interface Props {
  attrs: AttributesEntity[]
}

export function GetGnomeTier({ attrs }: Props): GnomeType {
  const tierValue = attrs?.find(att => att?.trait_type === 'tier')?.value

  if (tierValue === 'S') return GnomeType.S
  if (tierValue === 'A') return GnomeType.A
  if (tierValue === 'B') return GnomeType.B
  if (tierValue === 'C') return GnomeType.C

  return GnomeType.Unknow
}
