const gnomesPath = './assets/images/gnomes'
export const gnomes = {
  epic: `${gnomesPath}/common.png`,
  common: `${gnomesPath}/rare.png`,
  legendary: `${gnomesPath}/epic.png`,
  rare: `${gnomesPath}/legendary.png`,
}

const logoPath = './assets/images/logo'
export const logo = {
  blastedGnomes: `${logoPath}/blasted_gnomes.png`,
  platinum: `${logoPath}/platinum.png`,
  gold: `${logoPath}/gold.png`,
  silver: `${logoPath}/silver.png`,
  bronce: `${logoPath}/bronce.png`,
  ceramic: `${logoPath}/ceramic.png`,
  ripio: `${logoPath}/ripio_logo.png`,
  digi: `${logoPath}/digi_logo.png`,
  mini_ripio: `${logoPath}/mini_ripio.png`,
}

const backgroundPath = './assets/images/background'
export const background = {
  tableContainer: `${backgroundPath}/table_container.svg`,
  tableHorizontal: `${backgroundPath}/table_horizontal.svg`,
  nature: `.${backgroundPath}/nature.png`,
  natureWaves: `${backgroundPath}/nature_waves.png`,
  woodLeft: `${backgroundPath}/left_wood.svg`,
  woodRigth: `${backgroundPath}/right_wood.svg`,
  buttonShadow: `${backgroundPath}/button_shadow.svg`,
  orangeWaves: `${backgroundPath}/orange_waves.svg`,
  forest: `${backgroundPath}/forest.png`,
  main: `${backgroundPath}/main_bg.png`,
}

const commonPath = './assets/images/common'
export const commons = {
  dash: `${commonPath}/dash.svg`,
  gnomesWood: `${commonPath}/gnomes_wood.svg`,
  characterContainer: `${commonPath}/character_container.svg`,
  cloudUp: `${commonPath}/cloud_up.svg`,
  cloudMiddle: `${commonPath}/cloud_middle.svg`,
  board: `${commonPath}/board.svg`,
  teamMember: `${commonPath}/team_member.png`,
  cloudDown: `${commonPath}/cloud_down.svg`,
  brownWaves: `${commonPath}/brown_waves.png`,
  gnome: './assets/images/gnomes/gnome_preview.png',
  gnomePreview: './assets/images/gnomes/gnome_preview_2.png',
  chest: `${commonPath}/chest.png`,
  accountAvatar: `${commonPath}/account_avatar.png`,
  lacSymbol: `${commonPath}/lac_symbol.svg`,
  mushroom: `${commonPath}/mushroom.png`,
}

const iconsPath = './assets/images/icons'
export const icons = {
  cross: `${iconsPath}/cross.svg`,
  chevronDown: `${iconsPath}/chevron_down.svg`,
  swap: `${iconsPath}/swap.svg`,
  copy: `${iconsPath}/icon_copy.svg`,
  link: `${iconsPath}/icon_external_link.svg`,
  linkWhite: `${iconsPath}/link_white_icon.svg`,
  metamask: `${iconsPath}/wallet_metamask_icon.svg`,
  upArrow: `${iconsPath}/up_arrow_icon.svg`,
  leftArrow: `${iconsPath}/chevron_left_icon.svg`,
  infoBlueIcon: `${iconsPath}/info_blue_icon.svg`,
  alertFeedback: `${iconsPath}/alert_feedback_icon.svg`,
  featured: `${iconsPath}/featured_icon.svg`,
}
