import { css, styled } from '@mui/material'

export const PaginationWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto 0;
  `,
)

export const GnomesList = styled('div')(
  () => css`
    width: 100%;
    max-width: 1140px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
    flex-flow: row wrap;
    gap: 16px;
    margin: 0 auto;
  `,
)

export const GnomeMarketMasterContainer = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 40px;
  `,
)
