import React, { useCallback, useEffect, useState } from 'react'

import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { MarketplaceGnome } from '../../../Marketplace/models/MarketplaceGnome'
import {
  API_BACKEND_URL,
  SnackbarTranslations,
  Translations,
} from '../../../../config'

import { GnomesAdapted } from '../../../../commons/types'
import { fetchGnome, gnomesPath } from '../../../../services/backend'
import { GnomeCardNew } from '../../../../commons/components/GnomeCardNew'
import { GnomeAdapter } from '../../../GnomesTeam/utils/GnomeAdapter'
import { LoaderWrapper } from '../../../Inventory/Inventory.style'
import { useWalletContext } from '../../../../commons/contexts/WalletContext'

interface Props {
  gnome: MarketplaceGnome
}

export function GnomeMarketRequest({ gnome }: Props) {
  /* Translations */
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation([Translations.SNACKBAR])

  /* Handle loading */
  const [loadingItems, setLoadingItems] = useState<boolean>(false)

  /* Request Gnome Handler */
  const [finalGnome, setFinalGnome] = useState<GnomesAdapted | null>(null)

  const getGnome = useCallback(async () => {
    try {
      setLoadingItems(true)

      const useLastNumberAsId = `${API_BACKEND_URL}${gnomesPath}/`
      const finalIdGnome = gnome?.gnome
        ?.split(useLastNumberAsId)
        ?.pop()
        ?.toString()

      const data = await fetchGnome(finalIdGnome ?? '')

      const adapt = GnomeAdapter({
        gnomes: [data],
        price: gnome?.price,
        owner: gnome?.seller,
      })

      setFinalGnome(adapt?.[0])
    } catch {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_RETRIEVE_GNOME), {
        variant: 'error',
      })
      setLoadingItems(false)
    } finally {
      setLoadingItems(true)
    }
  }, [enqueueSnackbar, t, gnome?.gnome, gnome?.price, gnome?.seller])

  useEffect(() => {
    getGnome()
  }, [getGnome])

  /* Selected Gnome handler */
  const { setDetailsGnome } = useWalletContext()

  if (loadingItems && !finalGnome)
    return (
      <LoaderWrapper>
        <CircularProgress size={32} />
      </LoaderWrapper>
    )

  return (
    <GnomeCardNew
      key={gnome?.id}
      gnome={finalGnome}
      showGnome={() => setDetailsGnome(finalGnome)}
      forSale
      reloadGnomes={() => ''}
    />
  )
}
