import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, Translations } from '../../../config'
import { icons } from '../../../images'
import { OptionProps, SortValues } from '../../types'

import {
  ChevronIcon,
  ItemAction,
  ItemText,
  OptionIcon,
  OptionItem,
  OptionList,
  SelectSortContainer,
  SelectStyled,
  SelectedItemText,
  SelectedItemWrapper,
} from './SelectSort.styled'

interface SelectSortProps {
  options?: OptionProps[]
  disabled?: boolean
  defaultOptions?: boolean
  onFilter: (filter: string) => void
}

export function SelectSort({
  options,
  disabled,
  defaultOptions,
  onFilter,
}: SelectSortProps) {
  const { t } = useTranslation([Translations.COMMON])

  /* Set initial values */
  const initialValue: OptionProps = {
    value: SortValues.SelectAnOption,
    text: `${t(CommonTranslations.SORT_INITIAL_VALUE)}`,
  }

  const initialOptions: OptionProps[] = [
    {
      value: SortValues.ExpMinorToMajor,
      text: `${t(CommonTranslations.SORT_EXP_MINOR_TO_MAJOR)}`,
    },
    {
      value: SortValues.ExpMajorToMinor,
      text: `${t(CommonTranslations.SORT_EXP_MAJOR_TO_MINOR)}`,
    },
    {
      value: SortValues.Name1To999,
      text: `${t(CommonTranslations.SORT_NAME_1_to_999)}`,
    },
    {
      value: SortValues.Name999To1,
      text: `${t(CommonTranslations.SORT_NAME_999_to_1)}`,
    },
    /* {
      value: SortValues.RecentlyListed,
      text: `${t(CommonTranslations.SORT_RECENTLY_LISTED)}`,
    }, */
  ]

  /* Handle Sort options */
  const listedOptions: OptionProps[] | undefined = defaultOptions
    ? initialOptions
    : options

  const [showList, setShowList] = useState<boolean>(false)
  const handleList = () => setShowList(!showList)

  const [selectedOption, setSelectedOption] = useState<OptionProps>(
    options?.[0] ?? initialValue,
  )
  const handleSetOption = (option: OptionProps) => {
    setSelectedOption(option)
    setShowList(false)
  }

  return (
    <SelectSortContainer
      disabled={disabled || (!options?.length && !defaultOptions)}
    >
      <SelectStyled>
        <SelectedItemWrapper onClick={handleList}>
          {selectedOption?.icon && <OptionIcon src={selectedOption?.icon} />}
          <SelectedItemText>{selectedOption?.text}</SelectedItemText>

          <ChevronIcon src={icons.chevronDown} active={showList} />
        </SelectedItemWrapper>

        {showList && (
          <OptionList>
            {listedOptions?.map((option: OptionProps) => (
              <OptionItem key={option.value}>
                <ItemAction
                  onClick={() => {
                    handleSetOption(option)
                    onFilter(option?.value ?? '')
                  }}
                  active={option?.value === selectedOption?.value}
                >
                  {option.icon && <OptionIcon src={option.icon} />}
                  <ItemText> {option?.text}</ItemText>
                </ItemAction>
              </OptionItem>
            ))}
          </OptionList>
        )}
      </SelectStyled>
    </SelectSortContainer>
  )
}
