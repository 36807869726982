import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useAppContext } from '../../contexts/AppContext'
import { levelUpGnome } from '../../../services/backend'
import {
  CommonTranslations,
  SnackbarTranslations,
  TOKEN_SYMBOL,
  Translations,
} from '../../../config'

import { ButtonValues, GnomesAdapted } from '../../types'
import { UpLevelTag } from './Components/UpLevelTag/UpLevelTag'
import { CommonButton } from '../CommonButton/CommonButton'
import { commons, icons } from '../../../images'
import { GnomeTag } from '../GnomeTag/GnomeTag'
import { ModalCommon } from '../ModalCommon/ModalCommon'
import { ModalSuccessTsx } from '../ModalCommon/Modals/ModalSuccessTsx'

import {
  BodyInfoWrapper,
  CardBodyWrapper,
  CardHeaderImage,
  CardHeaderWrapper,
  GnomeCardNewContainer,
  GnomeId,
  GnomeSkill,
  GnomeSkillsWrapper,
  HeaderOptionsTeam,
  HeaderPriceInfo,
  HeaderPriceWrapper,
  InfoTokenSymbol,
  PriceToken,
  PriceWrapper,
} from './GnomeCardNew.style'

interface GnomeCardNewProps {
  gnome: GnomesAdapted | null
  forSale?: boolean
  hasItemToLvlUp?: boolean
  showGnome?: () => void
  reloadGnomes: () => void
}

export function GnomeCardNew({
  gnome,
  forSale,
  hasItemToLvlUp,
  showGnome,
  reloadGnomes,
}: GnomeCardNewProps) {
  const { t } = useTranslation([Translations.COMMON])
  const { enqueueSnackbar } = useSnackbar()

  /* Modal UI handler */
  const [showSuccessLvlModal, setShowSuccessLvlModal] = useState<boolean>(false)
  const showModal = () => setShowSuccessLvlModal(true)
  const hideModal = () => setShowSuccessLvlModal(false)

  /* Level Up handler */
  const { session } = useAppContext()
  const [levelUploading, setLevelUploading] = useState<boolean>(false)

  const handleLevelUp = async () => {
    if (!gnome) return
    setLevelUploading(true)
    try {
      await levelUpGnome(gnome?.id, session ?? '')
      enqueueSnackbar(t(SnackbarTranslations.LEVEL_UP_SUCCESS), {
        variant: 'success',
      })
      showModal()
      reloadGnomes()
    } catch (error) {
      let message
      switch (error.response.status) {
        case 400:
          message = SnackbarTranslations.UNABLE_TO_LEVEL_UP
          break
        case 404:
          message = SnackbarTranslations.GNOME_NOT_FOUND
          break
        default:
          message = SnackbarTranslations.COULD_NOT_LEVEL_UP
          break
      }
      enqueueSnackbar(t(message), { variant: 'error' })
    } finally {
      setLevelUploading(false)
    }
  }

  return (
    <>
      <GnomeCardNewContainer>
        <CardHeaderWrapper>
          <CardHeaderImage
            src={gnome?.metadata?.face_image ?? gnome?.metadata?.image}
          />

          {forSale && (
            <HeaderPriceWrapper>
              <HeaderPriceInfo>
                <PriceWrapper>
                  <InfoTokenSymbol src={commons.lacSymbol} />
                  <PriceToken>
                    {`${gnome?.metadata?.price_lac} ${TOKEN_SYMBOL}`}
                  </PriceToken>
                </PriceWrapper>
              </HeaderPriceInfo>
            </HeaderPriceWrapper>
          )}

          <HeaderOptionsTeam>
            {!forSale && (
              <CommonButton
                text={t(CommonTranslations.LEVEL_UP)}
                clickHandler={handleLevelUp}
                disabled={!gnome?.can_level_up || levelUploading}
                full
              />
            )}

            <CommonButton
              text={t(CommonTranslations.SEE_DETAILS)}
              iconSmall={icons.linkWhite}
              clickHandler={showGnome}
              variant={ButtonValues.Transparent}
              full
            />
          </HeaderOptionsTeam>
        </CardHeaderWrapper>

        <CardBodyWrapper>
          {gnome && <GnomeTag gnomeType={gnome?.metadata?.gnome_type} />}

          <BodyInfoWrapper>
            <GnomeId>{`#${gnome?.id}`}</GnomeId>

            <GnomeSkillsWrapper>
              <GnomeSkill>{`LV ${gnome?.level}`}</GnomeSkill>

              <GnomeSkill>{`XP ${gnome?.xp}`}</GnomeSkill>

              {gnome?.can_level_up && !forSale && <UpLevelTag />}

              {gnome?.can_level_up && !forSale && !hasItemToLvlUp && (
                <UpLevelTag missingToUp />
              )}
            </GnomeSkillsWrapper>
          </BodyInfoWrapper>
        </CardBodyWrapper>
      </GnomeCardNewContainer>

      <ModalCommon isShown={showSuccessLvlModal} closeModal={hideModal}>
        <ModalSuccessTsx clickHandler={hideModal} />
      </ModalCommon>
    </>
  )
}
