import React from 'react'
import { useTranslation } from 'react-i18next'
import { commons, icons } from '../../../images'
import { AddressShorten } from '../AddressShorten/AddressShorten'
import { CommonButton } from '../CommonButton/CommonButton'
import { ButtonValues } from '../../types'
import { CommonTranslations, TOKEN_SYMBOL, Translations } from '../../../config'
import { useWalletContext } from '../../contexts/WalletContext'
import { CopyButton } from '../CopyButton/CopyButton'

import {
  AddressAvatar,
  AddressAvatarWrapper,
  AddressWapper,
  BalanceWrapper,
  ButtonWapper,
  Capitalize,
  CopyButtonWrapper,
  CopyExplorerWrapper,
  ExplorerButton,
  HeaderAccountPop,
  HeaderPopIcon,
  HeaderPopTitle,
  IconButton,
  WalletDisconnectContainer,
} from './WalletDisconnect.style'

interface WalletDisconnectProps {
  shown: boolean
  address: string
  balance: string
}

export function WalletDisconnect({
  shown,
  address,
  balance,
}: WalletDisconnectProps) {
  /* Translations */
  const { t } = useTranslation([Translations.COMMON])

  /* LaChain Explorer Address */
  /* TO-DO: Add in  envs? */
  const laChainExplorerAddress = 'https://explorer.lachain.network/address/'

  /* Handle disconnect */
  const { disconnect } = useWalletContext()

  if (shown)
    return (
      <WalletDisconnectContainer>
        <HeaderAccountPop>
          <HeaderPopIcon src={icons.metamask} />
          <HeaderPopTitle>
            {t(CommonTranslations.CONNECTED_WITH)} &nbsp;
            <Capitalize>Metamask</Capitalize>
          </HeaderPopTitle>
        </HeaderAccountPop>

        <BalanceWrapper>{`${balance} ${TOKEN_SYMBOL}`}</BalanceWrapper>

        <AddressWapper>
          <AddressAvatarWrapper>
            <AddressAvatar src={commons.accountAvatar} />
            <AddressShorten address={address ?? ''} />
          </AddressAvatarWrapper>

          <CopyExplorerWrapper>
            <ExplorerButton
              href={`${laChainExplorerAddress}${address}`}
              target="_blank"
            >
              <IconButton src={`${icons.link}`} />
            </ExplorerButton>

            <CopyButton value={address}>
              <CopyButtonWrapper>
                <IconButton src={`${icons.copy}`} />
              </CopyButtonWrapper>
            </CopyButton>
          </CopyExplorerWrapper>
        </AddressWapper>

        <ButtonWapper>
          <CommonButton
            variant={ButtonValues.Secondary}
            text={t(CommonTranslations.DISCONNECT)}
            clickHandler={disconnect}
            full
          />
        </ButtonWapper>
      </WalletDisconnectContainer>
    )

  return null
}
