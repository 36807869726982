/* eslint-disable no-plusplus */
import { API_BACKEND_URL } from '../../../config'
import { itemsPath } from '../../../services/backend'
import { Action, Item } from '../models/Item'

interface Props {
  items: Item[]
  actions: Action[]
}

/**
 * Utility: Get the items from the 'Action' endpoint and compare them 
    with the list of Items from the 'Items' endpoint. Compare the ids in 
    both arrays and if the filtered item was used.
 *
 * @param items
 * @param actions
 *
 * @return Array of Items
 */
export function GetItemFinal({ items, actions }: Props): Item[] {
  const useLastNumberAsId = `${API_BACKEND_URL}${itemsPath}/`

  const itemFromActions = actions.map(acti => [
    Number(acti?.item?.split(useLastNumberAsId)?.pop()),
    acti?.processed,
  ])

  const filteredItems = items.filter(
    element =>
      itemFromActions?.[0]?.[0] === Number(element.id) &&
      itemFromActions?.[0]?.[1] === true,
  )

  return filteredItems
}
