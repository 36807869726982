import React from 'react'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, Translations } from '../../../../../config'
import { icons } from '../../../../../images'
import { TagValues } from '../../../../types'

import { UpLevelContainer, UpLevelIcon, UpLevelText } from './UpLevelTag.style'

interface UpLevelTagProps {
  missingToUp?: boolean
}

export function UpLevelTag({ missingToUp }: UpLevelTagProps) {
  const { t } = useTranslation([Translations.COMMON])

  if (missingToUp)
    return (
      <UpLevelContainer variant={TagValues.InfoLevelUp}>
        <UpLevelIcon src={icons.infoBlueIcon} />
        <UpLevelText>
          {`${t(CommonTranslations.MISSING_UP_LEVEL_GNOME)}`}
        </UpLevelText>
      </UpLevelContainer>
    )

  return (
    <UpLevelContainer variant={TagValues.CanLevelUp}>
      <UpLevelIcon src={icons.upArrow} />
      <UpLevelText>{t(CommonTranslations.UP_LEVEL_GNOME)}</UpLevelText>
    </UpLevelContainer>
  )
}
