import { createTheme, responsiveFontSizes } from '@mui/material'

const CustomTheme = createTheme({
  typography: {
    fontFamily: 'SF Pro Rounded',
  },
  palette: {
    primary: {
      main: '#FFBE17',
    },
    secondary: {
      main: '#000000',
    },
    font: {
      main: '#ffffff',
      black: '#000000',
      secondary: '#FFBE17',
      tertiary: '#000000',
      neutral01: '#ECECED',
      neutral05: '#F8F8F8',
      neutral10: '#F3F3F4',
      neutral20: '#B3B1B8',
      neutral40: '#8d8a95',
      neutral50: '#4F4B5C',
      neutral60: '#110c22',
      deepPurple: '#2E293D',
      pink: '#EA4183',
      pinkLight: '#FFF0F6',
      blue: '#0076E2',
      blueLight: '#E9F4FF',
      warning: '#D07D00',
      danger: '#F03D3D',
      warningLight: '#FFF5DB',
      warningDark: '#E09400',
      error: '#CF2A2A',
      epicGnome: '#CB17E8',
      infrequentGnome: '#4EC73F',
      rareGnome: '#1790E8',
    },
    button: {
      main: 'linear-gradient(180deg, #96C720 0%, #618F15 100%)',
      secondary: '#B8E773',
    },
    background: {
      primary: '#E0900E',
      secondary: '#400305',
      tertiary: '#45080B',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'transparent',
        },
        root: {
          height: '90px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '10px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          userSelect: 'none',
          color: '#000000',
          fontSize: '1.5rem',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '4em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#000000',
          textTransform: 'capitalize',
          border: '1px solid #000000',
          borderRadius: '20px',
          '&:hover': {
            color: '#000000',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: 'transparent',
          borderRadius: '25px',
          color: 'white',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
          width: '100vw',
        },
        html: {
          height: '100%',
          width: '100vw',
        },
      },
    },
  },
})

export default responsiveFontSizes(CustomTheme)
