import { useTranslation } from 'react-i18next'
import { CommonTranslations, Translations } from '../../../../../../config'
import { GnomeType } from '../../../../../../commons/types'

interface GetGnomeTypeProps {
  gnomeType: GnomeType | undefined
}

export function GetGnomeType({ gnomeType }: GetGnomeTypeProps) {
  const { t } = useTranslation([Translations.COMMON])

  if (!gnomeType) return `${t(CommonTranslations.GNOME_TYPE_UNKNOWN)}`

  if (gnomeType === GnomeType.S) return 'S'
  if (gnomeType === GnomeType.A) return 'A'
  if (gnomeType === GnomeType.B) return 'B'
  if (gnomeType === GnomeType.C) return 'C'

  if (gnomeType === GnomeType.Unknow)
    return `${t(CommonTranslations.GNOME_TYPE_UNKNOWN)}`
}
