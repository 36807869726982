import { css, styled } from '@mui/material'

export const ArrowIcon = styled('img')(
  () => css`
    width: 2rem;
    height: auto;
    flex: 0 0 auto;
  `,
)

export const BackButtonWrapper = styled('button')(
  ({ theme }) => css`
    width: 3rem;
    height: 3rem;
    background-color: ${theme.palette.font.main};
    border-radius: 8px;
    border: 1px solid ${theme.palette.font.neutral01};
    box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  `,
)

export const InfoTagLabel = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral60};
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.2px;
    display: flex;
    align-items: center;
    text-align: left;
    white-space: nowrap;
  `,
)

export const InfoTokenSymbol = styled('img')(
  () => css`
    width: 32px;
    height: 32px;
    flex: 0 0 auto;
  `,
)

export const InfoTagContent = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  `,
)

export const PriceWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    padding: 1.5rem 0;
    gap: 0.5rem;
  `,
)

export const AddressShortenWapper = styled('div')(
  ({ theme }) => css`
    * {
      color: ${theme.palette.font.neutral50};
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }
  `,
)

export const OwnerWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    gap: 0.2rem;
  `,
)

export const OwnerTitle = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral40};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  `,
)

export const TagExp = styled('span')(
  ({ theme }) => css`
    width: fit-content;
    border-radius: 8px;
    border: 1px solid ${theme.palette.font.neutral01};
    background: ${theme.palette.font.main};
    box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: ${theme.palette.font.neutral50};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
  `,
)

export const TagsWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  `,
)

export const GnomeImage = styled('img')(
  () => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  `,
)

export const GnomeInfoWrapper = styled('span')(
  ({ theme }) => css`
    width: 100%;
    height: auto;
    border-radius: 16px;
    border: 1px solid ${theme.palette.font.neutral10};
    background: ${theme.palette.font.main};
    box-shadow: 0px 16px 20px -8px rgba(17, 12, 34, 0.1);
    padding: 1.5rem;
    gap: 1.5rem;
    display: flex;
    flex-flow: column;
  `,
)

export const DetailRight = styled('span')(
  () => css`
    width: 50%;
  `,
)

export const DetailLeft = styled('div')(
  () => css`
    width: 50%;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    aspect-ratio: 1/1;
    box-shadow: 0px 32px 32px -12px rgba(17, 12, 34, 0.12);
  `,
)

export const GnomeDetailsWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  `,
)

export const ContentContainer = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 40px;
  `,
)

export const GnomeDetailsContainer = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 2rem;
  `,
)
