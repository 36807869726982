import { css, styled } from '@mui/material'

export const DescriptionTextModal = styled('div')(
  ({ theme }) => css`
    width: 100%;
    max-width: 300px;
    color: ${theme.palette.font.neutral40};
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin: 0 auto;
  `,
)

export const TitleTextModal = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral60};
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  `,
)

export const ModalSuccessTsxText = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    gap: 1rem;
  `,
)

export const WrapperButton = styled('div')(
  () => css`
    width: 100%;
    max-width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;
  `,
)

export const ModalSuccessTsxContainer = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
  `,
)
