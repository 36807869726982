import React from 'react'

import { Pagination } from '@mui/material'
import { MarketplaceGnome } from '../../../Marketplace/models/MarketplaceGnome'
import { CommonTitle } from '../../../../commons/components/CommonTitle/CommonTitle'

import {
  GnomeMarketMasterContainer,
  GnomesList,
  PaginationWrapper,
} from './GnomeMarketMaster.style'
import { GnomeMarketRequest } from '../GnomeMarketRequest'

interface Props {
  title?: string
  gnomes: MarketplaceGnome[]
  pages: number
  onRequestMore: (event: React.ChangeEvent<unknown>, page: number) => void
}

export function GnomeMarketMaster({
  title,
  gnomes,
  pages,
  onRequestMore,
}: Props) {
  const INITIAL_PAGE = 1

  return (
    <GnomeMarketMasterContainer>
      {title && <CommonTitle title={`${gnomes?.length} ${title}`} subtitle />}
      <GnomesList>
        {gnomes &&
          gnomes?.map((gnome: MarketplaceGnome) => (
            <GnomeMarketRequest key={gnome?.id} gnome={gnome} />
          ))}
      </GnomesList>

      <PaginationWrapper>
        <Pagination
          count={pages}
          page={INITIAL_PAGE}
          variant="outlined"
          shape="rounded"
          onChange={onRequestMore}
        />
      </PaginationWrapper>
    </GnomeMarketMasterContainer>
  )
}
