import { BrowserProvider } from 'ethers'
import { NETWORK_CHAIN_ID, NETWORK_CHAIN_NAME, NETWORK_CURRENCY_DECIMALS, NETWORK_CURRENCY_NAME, NETWORK_CURRENCY_SYMBOL, NETWORK_EXPLORER_URL, NETWORK_RPC_URL } from '../../config'

export const checkAndSwitchNetwork = async (provider: BrowserProvider) => {
  const chainId = await provider.send('eth_chainId', [])

  if (chainId !== NETWORK_CHAIN_ID) {
    try {
      await provider.send('wallet_switchEthereumChain', [{ chainId: NETWORK_CHAIN_ID }])
    } catch (switchError) {
      // this error code indicates that the chain has not been added to wallet
      if (switchError.code === 4902) {
        try {
          await provider.send('wallet_addEthereumChain', [
            {
              chainId: NETWORK_CHAIN_ID,
              chainName: NETWORK_CHAIN_NAME,
              rpcUrls: [NETWORK_RPC_URL],
              nativeCurrency: {
                name: NETWORK_CURRENCY_NAME,
                symbol: NETWORK_CURRENCY_SYMBOL,
                decimals: NETWORK_CURRENCY_DECIMALS,
              },
              blockExplorerUrls: [NETWORK_EXPLORER_URL],
            },
          ])
        } catch (addError) {
          throw new Error('Could not add network')
        }
      } else {
        throw new Error('Could not switch to network')
      }
    }
  }
}
