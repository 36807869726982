import { AxiosResponse } from 'axios'
import { backend } from './axios'
import { MarketplaceGnome } from '../../pages/Marketplace/models/MarketplaceGnome'
import { Gnome } from '../../commons/types'
import { Tournament } from '../../pages/Prize/models/Tournament'
import { Prize } from '../../pages/Prize/models/Prize'
import { Action, Item } from '../../pages/Inventory/models/Item'

export const gnomesPath = '/gnomes'
export const levelUpGnomePath = '/gnomes/:id/level_up'
export const salesPath = '/sales'
export const tournamentsPath = '/tournaments'
export const prizesPath = '/prizes'
export const itemsPath = '/items'
export const actionsPath = '/actions'

interface PaginationParameters {
  limit?: number
  offset?: number
  ordering?: string
}

interface PaginationResponse<T> {
  count: number
  next: string | null
  previous: string | null
  results: T[]
}

export interface GnomesParameters extends PaginationParameters {
  owner?: string
}

export interface PrizesParameters extends PaginationParameters {
  tournament__tournament_id?: number
  tournament?: string
}

export interface TournamentParameters extends PaginationParameters {
  active?: boolean
}

export interface ParamsSales extends PaginationParameters {
  seller?: string
  sold?: boolean
  cancelled?: boolean
  is_new?: boolean
}

export interface ParamsActions extends PaginationParameters {
  /* TO-DO */
  seller?: string
}

export type ItemParameters = PaginationParameters

type GnomesResponse = PaginationResponse<Gnome>

type MarketplaceGnomeResponse = PaginationResponse<MarketplaceGnome>

type TournamentResponse = PaginationResponse<Tournament>

type PrizeResponse = PaginationResponse<Prize>

type ItemResponse = PaginationResponse<Item>

type ActionsResponse = PaginationResponse<Action>

export async function fetchGnomes(
  params: GnomesParameters,
): Promise<GnomesResponse> {
  const { data } = await backend.get<GnomesResponse>(gnomesPath, { params })
  return data
}

export async function fetchGnome(id: string): Promise<Gnome> {
  const { data } = await backend.get<Gnome>(`${gnomesPath}/${id}`)
  return data
}

export async function fetchSales(
  params: ParamsSales,
): Promise<MarketplaceGnomeResponse> {
  const { data } = await backend.get<MarketplaceGnomeResponse>(salesPath, {
    params,
  })
  return data
}

export async function fetchTournament(
  params: TournamentParameters,
): Promise<TournamentResponse> {
  const { data } = await backend.get<TournamentResponse>(tournamentsPath, {
    params,
  })
  return data
}

export async function fetchPrizes(
  params: PrizesParameters,
): Promise<PrizeResponse> {
  const { data } = await backend.get<PrizeResponse>(prizesPath, { params })
  return data
}

export async function fetchItems(
  params: ItemParameters,
): Promise<ItemResponse> {
  const { data } = await backend.get<ItemResponse>(itemsPath, { params })
  return data
}

export async function fetchActions(session: string): Promise<ActionsResponse> {
  const { data } = await backend.get<ActionsResponse>(actionsPath, {
    headers: {
      Authorization: session,
    },
  })
  return data
}

export function levelUpGnome(
  id: number,
  session: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  return backend.post(
    levelUpGnomePath.replace(':id', id.toString()),
    {},
    {
      headers: {
        Authorization: session,
      },
    },
  )
}
