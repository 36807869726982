import { useCallback, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import { useWalletContext } from '../contexts/WalletContext'

const useGetBalance = () => {
  const { address, browserProvider } = useWalletContext()

  const [balance, setBalance] = useState<string>('')

  const activate = useCallback(async () => {
    try {
      if (!window.ethereum) return

      const balanceReq = await browserProvider?.getBalance(`${address}`)
      const value = ethers.formatUnits(`${balanceReq}`)
      const result = new BigNumber(value).toFixed(4)
      const rounded = new BigNumber(result).toFormat()

      setBalance(`${rounded}`)
    } catch (err) {
      /* TO-DO Handle error */
    }
  }, [address, browserProvider])

  useEffect(() => {
    activate()
  }, [activate])

  return { balance }
}

export default useGetBalance
