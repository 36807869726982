import { css, styled } from '@mui/material'

export const CloseIcon = styled('img')(
  () => css`
    width: 24px;
    height: 24px;
  `,
)

export const CloseWrapper = styled('button')(
  () => css`
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    padding: 4px;
    margin: 1rem 1rem -2rem auto;
    flex: 0 0 auto;
    &:hover {
      opacity: 0.4;
      cursor: pointer;
    }
  `,
)

export const DescriptionTextModal = styled('div')(
  ({ theme }) => css`
    width: 100%;
    max-width: 300px;
    color: ${theme.palette.font.neutral40};
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin: 0 auto;
  `,
)

export const TitleTextModal = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral60};
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  `,
)

export const DescriptionTextModalContainer = styled('div')(
  ({ theme }) => css`
    display: inline-flex;
    width: 90%;
    max-width: 500px;
    height: auto;
    padding: 1rem 1.5rem;
    margin: 0 2rem;
    padding-bottom: 2.5rem;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    border-radius: 6px;
    background: ${theme.palette.font.main};
    box-shadow: 0px 8px 30px 0px rgba(55, 55, 68, 0.08);
  `,
)

export const ModalCommonContainer = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 999;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
  `,
)
