import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSnackbar } from 'notistack'
import { useWalletContext } from '../../commons/contexts/WalletContext'
import { LIMIT_GNOMES_REQUEST } from '../../config'
import {
  CommonTranslations,
  SnackbarTranslations,
  Translations,
} from '../../config/i18n/i18n'
import { fetchSales } from '../../services/backend'
import { EmprtyWrapper } from '../Inventory/Inventory.style'

import { DashboardAppBar } from '../../commons/components/DashboardAppBar'
import { CommonTitle } from '../../commons/components/CommonTitle/CommonTitle'
import { MarketplaceGnome } from '../Marketplace/models/MarketplaceGnome'
import { GnomeMarketMaster } from './components/GnomeMarketMaster'
import { GnomeDetails } from '../GnomeDetails'

import {
  TeamContainer,
  ContentContainer,
  GnomeSortWrapper,
  TabRequestContainer,
  TabRequest,
  TabIcon,
} from './MarketPlaceNew.style'
import { icons } from '../../images'

export function MarketPlaceNew() {
  /* Translations */
  const { t } = useTranslation([Translations.COMMON])
  const { enqueueSnackbar } = useSnackbar()

  /* No magic numbers */
  const INITIAL_PAGE = 1
  const GNOMOS_MIN_QTY = 1

  const gnomesQty = (qty: number) => {
    if (qty === GNOMOS_MIN_QTY) return 'Gnomo'

    return 'Gnomos'
  }

  /* Tab sales gnomes */
  const [gnomesRequest, setGnomesRequest] = useState<'all' | 'featured'>('all')
  const allGnomesRequest = () => setGnomesRequest('all')
  const featuredGnomesRequest = () => setGnomesRequest('featured')

  /* Handle loading */
  const [loading, setLoading] = useState<boolean>(false)

  interface MarketplaceCategory {
    sellers: { gnomes: MarketplaceGnome[]; pages: number }
    community: { gnomes: MarketplaceGnome[]; pages: number }
  }

  const [marketplaceGnomes, setMarketplaceGnomes] =
    useState<MarketplaceCategory>({
      community: { gnomes: [], pages: INITIAL_PAGE },
      sellers: { gnomes: [], pages: INITIAL_PAGE },
    })

  const getCommunitySales = useCallback(
    async (page: number) => {
      try {
        setLoading(true)
        const data = await fetchSales({
          seller: '',
          sold: false,
          cancelled: false,
          is_new: false,
          limit: LIMIT_GNOMES_REQUEST,
          offset: (page - INITIAL_PAGE) * LIMIT_GNOMES_REQUEST,
        })
        const pages = Math.ceil(data.count / LIMIT_GNOMES_REQUEST)

        setMarketplaceGnomes(prev => ({
          ...prev,
          community: { pages, gnomes: data.results },
        }))
      } catch (err) {
        enqueueSnackbar(
          t(SnackbarTranslations.FAIL_TO_RETRIEVE_COMMUNITY_GNOMES),
          { variant: 'error' },
        )
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
    [enqueueSnackbar, t],
  )

  useEffect(() => {
    if (gnomesRequest === 'all') getCommunitySales(INITIAL_PAGE)
  }, [gnomesRequest, getCommunitySales])

  const getSellerSales = useCallback(
    async (page: number) => {
      const RIPIO_WALLET = '0x807502E6D5CC789eEBF48400C5E15F5592809C38'
      try {
        setLoading(true)
        const data = await fetchSales({
          seller: RIPIO_WALLET,
          sold: false,
          cancelled: false,
          is_new: false,
          limit: LIMIT_GNOMES_REQUEST,
          offset: (page - INITIAL_PAGE) * LIMIT_GNOMES_REQUEST,
        })
        const pages = Math.ceil(data.count / LIMIT_GNOMES_REQUEST)

        setMarketplaceGnomes(prev => ({
          ...prev,
          sellers: { pages, gnomes: data.results },
        }))
      } catch (err) {
        enqueueSnackbar(
          t(SnackbarTranslations.FAIL_TO_RETRIEVE_COMMUNITY_GNOMES),
          { variant: 'error' },
        )
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
    [enqueueSnackbar, t],
  )

  useEffect(() => {
    if (gnomesRequest === 'featured') getSellerSales(INITIAL_PAGE)
  }, [gnomesRequest, getSellerSales])

  /* On Request pagination handler */
  const onRequestMoreCommunity = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      getCommunitySales(page)
    },
    [getCommunitySales],
  )

  const onRequestMoreSeller = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      getCommunitySales(page)
    },
    [getCommunitySales],
  )

  const hasCommunityGnomes = !!marketplaceGnomes?.community?.gnomes?.length
  const hasSellerGnomes = !!marketplaceGnomes?.sellers?.gnomes?.length

  /* Selected Gnome handler */
  const { selectedGnome, removeDetailsGnome } = useWalletContext()

  if (selectedGnome)
    return (
      <TeamContainer>
        <DashboardAppBar />
        <ContentContainer>
          <GnomeDetails
            gnome={selectedGnome}
            backAction={removeDetailsGnome}
            forSale
          />
        </ContentContainer>
      </TeamContainer>
    )

  if (gnomesRequest === 'featured')
    return (
      <TeamContainer>
        <DashboardAppBar />

        <ContentContainer>
          <>
            <TabRequestContainer>
              <TabRequest onClick={allGnomesRequest}>
                {`${t(CommonTranslations.ALL)}`}
              </TabRequest>

              <TabRequest active onClick={featuredGnomesRequest}>
                <TabIcon src={icons.featured} />
                {`${t(CommonTranslations.FEATURED)}`}
              </TabRequest>
            </TabRequestContainer>

            <CommonTitle
              title={`${marketplaceGnomes?.sellers?.gnomes?.length} ${gnomesQty(
                marketplaceGnomes?.sellers?.gnomes?.length,
              )}`}
              subtitle
            />

            {!hasSellerGnomes && !loading && (
              <EmprtyWrapper>{`${t(
                CommonTranslations.EMPTY_ITEMS,
              )}`}</EmprtyWrapper>
            )}

            {hasSellerGnomes && (
              <GnomeSortWrapper>
                <GnomeMarketMaster
                  gnomes={marketplaceGnomes?.sellers?.gnomes}
                  pages={marketplaceGnomes?.sellers?.pages}
                  onRequestMore={onRequestMoreSeller}
                />
              </GnomeSortWrapper>
            )}
          </>
        </ContentContainer>
      </TeamContainer>
    )

  return (
    <TeamContainer>
      <DashboardAppBar />

      <ContentContainer>
        <>
          <TabRequestContainer>
            <TabRequest active onClick={allGnomesRequest}>
              {`${t(CommonTranslations.ALL)}`}
            </TabRequest>

            <TabRequest onClick={featuredGnomesRequest}>
              <TabIcon src={icons.featured} />
              {`${t(CommonTranslations.FEATURED)}`}
            </TabRequest>
          </TabRequestContainer>

          <CommonTitle
            title={`${marketplaceGnomes?.community?.gnomes?.length} ${gnomesQty(
              marketplaceGnomes?.community?.gnomes?.length,
            )}`}
            subtitle
          />

          {!hasCommunityGnomes && !loading && (
            <EmprtyWrapper>{`${t(
              CommonTranslations.EMPTY_ITEMS,
            )}`}</EmprtyWrapper>
          )}

          {hasCommunityGnomes && (
            <GnomeSortWrapper>
              <GnomeMarketMaster
                gnomes={marketplaceGnomes?.community?.gnomes}
                pages={marketplaceGnomes?.community?.pages}
                onRequestMore={onRequestMoreCommunity}
              />
            </GnomeSortWrapper>
          )}
        </>
      </ContentContainer>
    </TeamContainer>
  )
}
