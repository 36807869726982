import { css, styled } from '@mui/material'

interface Props {
  copied: boolean
}

export const CopyContainer = styled('button')<Props>(
  ({ copied }) => css`
    align-items: center;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: transparent;
    border: none;

    &::after {
      width: auto;
      align-items: center;
      background-color: black;
      border-radius: 0.5rem;
      color: white;
      padding: 0.375rem;
      content: attr(data-tooltip);
      display: flex;
      flex: 0 0 auto;
      font-size: 1.1rem;
      justify-content: center;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s ease-in-out;
      transform: translate(-50%, -107%);
      position: absolute;
      left: 50%;
      top: -5px;
    }

    ${copied &&
    css`
      opacity: 1;
      pointer-events: none;
      &::after {
        opacity: 1;
      }
    `}
  `,
)
