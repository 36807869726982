import { styled, css } from '@mui/material'

export const PriceInput = styled('input')(
  ({ theme }) => css`
    border-radius: 8px;
    padding: 0.2rem 0.8rem;
    height: 30px;
    border: 1px solid ${theme.palette.font.neutral05};
    color: ${theme.palette.font.neutral60};
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    box-shadow: 0px 2px 4px 0px rgba(17, 12, 34, 0.12), 0px 0px 0px 4px #ffecba;
  `,
)

export const LabelInput = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.font.neutral50};
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
  `,
)

export const InputWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
  `,
)

export const InfoDesc = styled('div')(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.palette.font.neutral40};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  `,
)

export const InfoTitle = styled('div')(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.palette.font.neutral60};
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  `,
)

export const InfoWrapper = styled('div')(
  () => css`
    width: 100%;
    gap: 0.5rem;
    display: flex;
    flex-flow: column;
  `,
)

export const ImageId = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.font.main};
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
  `,
)

export const ImageInfo = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    gap: 1rem;
    position: relative;
    z-index: 3;
  `,
)

export const ImageGnome = styled('img')(
  () => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    transform: scale(1.5);
    position: absolute;
    top: 0;
    left: 0;
  `,
)

export const ImageOver = styled('div')(
  () => css`
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.75) 100%
    );
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
  `,
)

export const ImageWrapper = styled('div')(
  () => css`
    width: 15rem;
    height: 15rem;
    display: flex;
    gap: 1rem;
    padding: 1.5rem;
    overflow: hidden;
    border-radius: 1rem;
    position: relative;
    flex: 0 0 auto;
  `,
)

export const GnomeInfoWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    gap: 1rem;
    padding-top: 1rem;
  `,
)

export const SellGnomeModalContainer = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
  `,
)
