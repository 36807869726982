import { css, styled } from '@mui/material'
import { GnomeType } from '../../types'

interface GnomeTypeProps {
  gnomeType: GnomeType
}

export const GnomeTagContainer = styled('div')<GnomeTypeProps>(
  ({ theme, gnomeType }) => css`
    width: fit-content;
    height: 24px;
    color: ${theme.palette.font.main};
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: initial;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    box-sizing: border-box;
    color: ${theme.palette.font.main};
    background-color: ${theme.palette.font.warning};
    white-space: nowrap;

    ${gnomeType === GnomeType.S &&
    css`
      background-color: ${theme.palette.font.epicGnome};
    `}

    ${gnomeType === GnomeType.A &&
    css`
      background-color: ${theme.palette.font.rareGnome};
    `}

  ${gnomeType === GnomeType.B &&
    css`
      background-color: ${theme.palette.font.infrequentGnome};
    `}

${gnomeType === GnomeType.C &&
    css`
      background-color: ${theme.palette.font.warning};
    `}
  `,
)
