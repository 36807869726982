export enum Translations {
  SNACKBAR = 'snackbar',
  COMMON = 'common',
}

export enum CommonTranslations {
  DASHBOARD = 'common:dashboard',
  PLAY = 'common:play',
  CHESTS = 'common:chests',
  CHEST = 'common:chest',
  PRIZE = 'common:prize',
  PRIZES = 'common:prizes',
  MY_TEAM = 'common:my_team',
  MY_TEAM_MARKETPLACE = 'common:my_team_marketplace',
  INVENTORY = 'common:inventory',
  ITEMS = 'common:items',
  GET_A_GNOME = 'common:get_a_gnome',
  RANDOM_GNOME = 'common:random_gnome',
  INCLUDES = 'common:includes',
  BUY = 'common:buy',
  BUY_ANOTHER = 'common:buy_another',
  GOT_GNOME = 'common:got_gnome',
  GOT_GNOME_DISCLAIMER = 'common:got_gnome_disclaimer',
  GNOMES_TEAM_TITLE = 'common:gnomes_team_title',
  GNOMES_CARD_TIER = 'common:gnomes_card_tier',
  GNOMES_CARD_LEVEL = 'common:gnomes_card_level',
  GNOMES_CARD_XP = 'common:gnomes_card_xp',
  SELL = 'common:sell',
  SELL_DESC = 'common:sell_desc',
  LEVEL_UP = 'common:level_up',
  CANCEL = 'common:cancel',
  MARKETPLACE = 'common:marketplace',
  NEW = 'common:new',
  NEW_MARKETPLACE = 'common:new_marketplace',
  COMMUNITY = 'common:community',
  COMMUNITY_MARKETPLACE = 'common:community_marketplace',
  CONFIRM = 'common:confirm',
  CONFIRMING = 'common:confirming',
  ITEM_DETAILS = 'common:item_details',
  PRICE = 'common:price',
  POSITION = 'common:position',
  ACTIVE_TOURNAMENT = 'common:active_tournament',
  PREVIOUS_TOURNAMENT = 'common:previous_tournament',
  TRANSACTION_HASH = 'common:transaction_hash',
  NFT_TRANSACTION_HASH = 'common:nft_transaction_hash',
  NAME = 'common:name',
  OPERATION = 'common:operation',
  AWAITING_TSX = 'common:awaiting_tsx',
  HOLD = 'common:hold',
  REQUEST_ERROR = 'common:request_error',
  MODAL_BUY_CHEST_MODAL_TITLE = 'common:modal_buy_chest_modal_title',
  MODAL_BUY_CHEST_MODAL_SUBTITLE = 'common:modal_buy_chest_modal_subtitle',
  MODAL_BUY_CHEST_MODAL_MAIN_BUTTON = 'common:modal_buy_chest_modal_main_button',
  MODAL_BUY_CHEST_MODAL_SECONDARY_BUTTON = 'common:modal_buy_chest_modal_secondary_button',

  MODAL_SUCCESS_TSX_TITLE = 'common:modal_success_tsx_title',
  MODAL_SUCCESS_TSX_SUBTITLE = 'common:modal_success_tsx_subtitle',
  MODAL_SUCCESS_TSX_BUTTON = 'common:modal_success_tsx_button',

  SORT_INITIAL_VALUE = 'common:sort_initial_value',
  SORT_EXP_MINOR_TO_MAJOR = 'common:sort_exp_minor_to_major',
  SORT_EXP_MAJOR_TO_MINOR = 'common:sort_exp_major_to_minor',
  SORT_NAME_1_to_999 = 'common:sort_name_1_to_999',
  SORT_NAME_999_to_1 = 'common:sort_name_999_to_1',
  SORT_RECENTLY_LISTED = 'common:sort_recently_listed',

  GNOME_TYPE_EPIC = 'common:gnome_type_epic',
  GNOME_TYPE_RARE = 'common:gnome_type_rare',
  GNOME_TYPE_INFREQUENT = 'common:gnome_type_infrequent',
  GNOME_TYPE_UNKNOWN = 'common:gnome_type_unknown',

  COPIED = 'common:copied',

  CONNECTED_WITH = 'common:connected_with',
  DISCONNECT = 'common:disconnect',
  CONNECT = 'common:connect',
  CONNECTING = 'common:connecting',
  UP_LEVEL_GNOME = 'common:up_level_gnome',
  MISSING_UP_LEVEL_GNOME = 'common:missing_up_level_gnome',
  SEE_ON_MARKETPLACE = 'common:see_on_marketplace',
  SEE_DETAILS = 'common:see_details',
  CANCEL_SELL = 'common:cancel_sell',
  PANEL_INVENTORY_BALANCE = 'common:panel_inventory_balance',
  PANEL_INVENTORY_MUSHROOMS = 'common:panel_inventory_mushrooms',
  INVENTORY_MUSHROOM = 'common:inventory_mushroom',
  GO_TO_INVENTORY = 'common:go_to_inventory',
  OWNER = 'common:owner',
  CURRENT_PRICE = 'common:current_price',
  MUSHROOM_DESC = 'common:mushroom_desc',
  EMPTY_ITEMS = 'common:empty_items',
  TOTAL_IN = 'common:total_in',
  ALL = 'common:all',
  FEATURED = 'common:featured',
}

export enum SnackbarTranslations {
  COULD_NOT_CONNECT_TO_WALLET = 'snackbar:could_not_connect_to_wallet',
  COULD_NOT_CONNECT_TO_CONTRACT = 'snackbar:could_not_connect_to_contract',
  CANNOT_GET_CHEST = 'snackbar:cannot_get_chest',
  CANNOT_BUY_CHEST = 'snackbar:cannot_buy_chest',
  COULD_NOT_RETRIEVE_TOKENS = 'snackbar:could_not_retrieve_tokens',
  COULD_NOT_SORT_TOKENS = 'snackbar:could_not_sort_tokens',
  COULD_NOT_RETRIEVE_GNOME = 'snackbar:could_not_retrieve_gnome',
  COULD_NOT_RETRIEVE_PRIZES = 'snackbar:could_not_retrieve_prizes',
  COULD_NOT_RETRIEVE_TOURNAMENT = 'snackbar:could_not_retrieve_tournament',
  COULD_NOT_RETRIEVE_ITEMS = 'snackbar:could_not_retrieve_items',
  COULD_NOT_LEVEL_UP = 'snackbar:could_not_level_up',
  LEVEL_UP_SUCCESS = 'snackbar:level_up_success',
  FAIL_TO_RETRIEVE_COMMUNITY_GNOMES = 'snackbar:fail_to_retrieve_community_gnomes',
  FAIL_TO_RETRIEVE_MY_GNOMES = 'snackbar:fail_to_retrieve_my_gnomes',
  FAIL_TO_RETRIEVE_NEW_GNOMES = 'snackbar:fail_to_retrieve_new_gnomes',
  CANNOT_BUY_ITEM = 'snackbar:cannot_buy_item',
  FAIL_TO_LIST_ITEM = 'snackbar:fail_to_list_item',
  FAIL_TO_REMOVE_ITEM = 'snackbar:fail_to_remove_item',
  CANNOT_BUY_GNOME = 'snackbar:cannot_buy_gnome',
  SESSION_STARTED = 'snackbar:session_started',
  CANNOT_START_SESSION = 'snackbar:cannot_start_session',
  CANNOT_STORE_COOKIE = 'snackbar:cannot_store_cookie',
  CANNOT_REMOVE_COOKIE = 'snackbar:cannot_remove_cookie',
  BOUGHT_ITEM = 'snackbar:bought_item',
  GNOME_NOT_FOUND = 'snackbar:gnome_not_found',
  UNABLE_TO_LEVEL_UP = 'snackbar:unable_to_level_up',
}
