export const ANALYTICS_MEASUREMENT_ID =
  process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID
export const API_LAMBDA_URL = process.env.REACT_APP_API_LAMBDA_URL
export const GNOMES_CONTRACT_ADDRESS =
  process.env.REACT_APP_GNOMES_CONTRACT_ADDRESS
export const IAP_CONTRACT_ADDRESS = process.env.REACT_APP_IAP_CONTRACT_ADDRESS
export const API_BACKEND_URL = process.env.REACT_APP_API_BACKEND_URL
export const LIMIT_GNOMES_REQUEST =
  Number(process.env.REACT_APP_LIMIT_GNOMES_REQUEST) || 10
export const MARKETPLACE_CONTRACT_ADDRESS =
  process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS
export const TOKEN_SYMBOL = process.env.REACT_APP_TOKEN_SYMBOL
export const NETWORK_CHAIN_ID = process.env.REACT_APP_NETWORK_CHAIN_ID
export const NETWORK_CHAIN_NAME = process.env.REACT_APP_NETWORK_CHAIN_NAME
export const NETWORK_CURRENCY_NAME = process.env.REACT_APP_NETWORK_CURRENCY_NAME
export const NETWORK_CURRENCY_SYMBOL = process.env.REACT_APP_NETWORK_CURRENCY_SYMBOL
export const NETWORK_CURRENCY_DECIMALS = process.env.REACT_APP_NETWORK_CURRENCY_DECIMALS
export const NETWORK_EXPLORER_URL = process.env.REACT_APP_NETWORK_EXPLORER_URL
export const NETWORK_RPC_URL = process.env.REACT_APP_NETWORK_RPC_URL
