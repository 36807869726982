import { styled, css } from '@mui/material'

export const FeedbackMsgIcon = styled('img')(
  () => css`
    width: 12px;
    height: auto;
    flex: 0 0 auto;
    margin-right: 2px;
  `,
)

export const FeedbackMsgContainer = styled('div')(
  ({ theme }) => css`
    width: 100%;
    color: ${theme.palette.font.neutral50};
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
  `,
)
