/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import { useAppContext } from '../../commons/contexts/AppContext'
import { Item } from './models/Item'
import { fetchActions, fetchItems } from '../../services/backend'
import { GetItemFinal } from './utils/GetItemFinal'
import {
  CommonTranslations,
  SnackbarTranslations,
  Translations,
} from '../../config/i18n/i18n'

import { DashboardAppBar } from '../../commons/components/DashboardAppBar'
import { CommonTitle } from '../../commons/components/CommonTitle/CommonTitle'
import { InventoryItem } from '../../commons/components/InventoryItem'

import {
  ContentContainer,
  EmprtyWrapper,
  InventoryItemContainer,
  ItemsContainer,
  LoaderWrapper,
} from './Inventory.style'

export function Inventory() {
  /* Translations */
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation([Translations.COMMON, Translations.SNACKBAR])

  /* Session */
  const { session } = useAppContext()

  /* Handle loading */
  const [loadingItems, setLoadingItems] = useState<boolean>(false)

  /* Handle Items */
  const [items, setItems] = useState<Item[]>([])
  const getItems = useCallback(async () => {
    try {
      setLoadingItems(true)

      const actions = await fetchActions(session ?? '')
      const itemsRes = await fetchItems({})

      if (actions && itemsRes) {
        const final = GetItemFinal({
          items: itemsRes?.results,
          actions: actions?.results,
        })
        setItems(final)
      }
    } catch (err) {
      enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_RETRIEVE_ITEMS), {
        variant: 'error',
      })
      setLoadingItems(false)
    } finally {
      setLoadingItems(false)
    }
  }, [enqueueSnackbar, session, t])

  useEffect(() => {
    getItems()
  }, [getItems])

  return (
    <ItemsContainer
      id="inventory-section"
      data-testid="inventory:page-container"
    >
      <DashboardAppBar />

      <ContentContainer data-testid="inventory:content-container">
        <CommonTitle
          title={`${t(CommonTranslations.INVENTORY)}`}
          data-testid="inventory:page-title"
        />

        {loadingItems && (
          <LoaderWrapper>
            <CircularProgress size={32} />
          </LoaderWrapper>
        )}

        {!loadingItems && !!items?.length && (
          <InventoryItemContainer data-testid="inventory:items-container">
            <InventoryItem qty={items?.length} item={items?.[0]} />
          </InventoryItemContainer>
        )}

        {!loadingItems && !items?.length && (
          <EmprtyWrapper>{`${t(
            CommonTranslations.EMPTY_ITEMS,
          )}`}</EmprtyWrapper>
        )}
      </ContentContainer>
    </ItemsContainer>
  )
}
