export enum TagValues {
  CanLevelUp = 'Up',
  InfoLevelUp = 'Info',
}

export enum ButtonValues {
  Primary = 'primary',
  Danger = 'danger',
  Secondary = 'secondary',
  White = 'white',
  Square = 'square',
  Transparent = 'transparent',
}

export enum GnomeType {
  S = 'S',
  A = 'A',
  B = 'B',
  C = 'C',
  Unknow = 'unknow',
}

export enum SortValues {
  SelectAnOption = '',
  ExpMajorToMinor = 'experience-major-to-minor',
  ExpMinorToMajor = 'experience-minor-to-major',
  Name1To999 = 'name-1-to-999',
  Name999To1 = 'name-999-to-1',
  RecentlyListed = 'recently-listed',
}

export interface OptionProps {
  value?: SortValues
  text?: string
  icon?: string
}

export interface Gnome {
  id: number
  owner: string
  can_level_up: boolean
  metadata: Metadata
}

export interface Metadata {
  image: string
  face_image?: string
  gnome_type?: GnomeType
  price_lac?: string
  attributes: AttributesEntity[]
}

export interface AttributesEntity {
  value: string | number
  trait_type: string
}

export interface GnomesAdapted {
  id: number
  owner: string
  can_level_up: boolean
  level: string | number | undefined
  xp: string | number | undefined
  metadata: {
    image: string
    face_image: string
    gnome_type: GnomeType
    price_lac: string | null
    attributes: AttributesEntity[]
  }
}

export interface GnomeSaleAdapted {
  marketplace_id: string
  price_lac: string | null
  canceled: boolean
  sold: boolean
  buyer: string
  seller: string
  gnome: string
}
