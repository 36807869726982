import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, useTheme } from '@mui/material'
import { CommonTranslations, Translations } from '../../../config/i18n/i18n'
import { MobileAppBar } from './MobileAppBar'
import { DesktopAppBar } from './DesktopAppBar'
import { RoutesEnum } from '../../../routes/constants'
import { logo } from '../../../images'
import { WalletButton } from '../WalletButton/WalletButton'
import { useWalletContext } from '../../contexts/WalletContext'

import {
  BarNavBorder,
  BarNavBrand,
  DashboardAppBarNav,
} from './DashboardAppBar.style'

function DashboardAppBar() {
  const { t } = useTranslation([Translations.COMMON, Translations.SNACKBAR])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const dashboardRoutes = useMemo(
    () => [
      {
        id: 'team',
        path: RoutesEnum.TEAM,
        title: t(CommonTranslations.MY_TEAM),
      },
      {
        id: 'inventory',
        path: RoutesEnum.INVENTORY,
        title: t(CommonTranslations.INVENTORY),
      },
      {
        id: 'chests',
        path: RoutesEnum.CHEST,
        title: t(CommonTranslations.CHESTS),
      },
      {
        id: 'play',
        path: RoutesEnum.PLAY,
        title: t(CommonTranslations.PLAY),
      },
      {
        id: 'marketplace',
        path: RoutesEnum.MARKETPLACE,
        title: t(CommonTranslations.MARKETPLACE),
      },
    ],
    [t],
  )

  /* Handle disconnect */
  const { removeDetailsGnome } = useWalletContext()
  useEffect(() => {
    removeDetailsGnome()
  }, [removeDetailsGnome])

  return (
    <DashboardAppBarNav>
      <BarNavBorder />
      <BarNavBrand src={logo.blastedGnomes} />

      {isMobile && <MobileAppBar routes={dashboardRoutes} />}

      {!isMobile && <DesktopAppBar routes={dashboardRoutes} />}

      <WalletButton />
    </DashboardAppBarNav>
  )
}

export default DashboardAppBar
