import React from 'react'
import { useTranslation } from 'react-i18next'
import { CommonTranslations, Translations } from '../../../../../config'

import { CommonButton } from '../../../CommonButton/CommonButton'
import { ButtonValues } from '../../../../types'

import {
  ModalSuccessTsxContainer,
  TitleTextModal,
  DescriptionTextModal,
  ModalSuccessTsxText,
  WrapperButton,
} from './ModalSuccessTsx.style'

interface Props {
  clickHandler: () => void
}

export function ModalSuccessTsx({ clickHandler }: Props) {
  /* Translations */
  const { t } = useTranslation([Translations.COMMON])

  return (
    <ModalSuccessTsxContainer>
      <ModalSuccessTsxText>
        <TitleTextModal>
          {t(CommonTranslations.MODAL_SUCCESS_TSX_TITLE)}
        </TitleTextModal>

        <DescriptionTextModal>
          {t(CommonTranslations.MODAL_SUCCESS_TSX_SUBTITLE)}
        </DescriptionTextModal>
      </ModalSuccessTsxText>

      <WrapperButton>
        <CommonButton
          variant={ButtonValues.White}
          text={t(CommonTranslations.MODAL_SUCCESS_TSX_BUTTON)}
          clickHandler={clickHandler}
          full
        />
      </WrapperButton>
    </ModalSuccessTsxContainer>
  )
}
