import { useCallback, useEffect, useState } from 'react'

import { GnomesAdapted, SortValues } from '../../../commons/types'

interface GnomesFilteredProps {
  gnomesArr: GnomesAdapted[]
  filter: string | null
}

export function GnomesFiltered({
  gnomesArr,
  filter,
}: GnomesFilteredProps): GnomesAdapted[] {
  /* Handle sort gnomes list */
  const [gnomesFiltered, setGnomesFiltered] = useState<GnomesAdapted[] | []>(
    gnomesArr,
  )

  const handleSort = useCallback(() => {
    if (filter === SortValues.ExpMinorToMajor) {
      const ExpMinorToMajor = [...gnomesArr].sort(
        (a, b) => Number(a.xp) - Number(b.xp),
      )
      setGnomesFiltered(ExpMinorToMajor)
    }

    if (filter === SortValues.ExpMajorToMinor) {
      const ExpMajorToMinor = [...gnomesArr].sort(
        (a, b) => Number(b.xp) - Number(a.xp),
      )
      setGnomesFiltered(ExpMajorToMinor)
    }

    if (filter === SortValues.Name1To999) {
      const Name1To999 = [...gnomesArr].sort(
        (a, b) => Number(a.id) - Number(b.id),
      )
      setGnomesFiltered(Name1To999)
    }

    if (filter === SortValues.Name999To1) {
      const Name999To1 = [...gnomesArr].sort(
        (a, b) => Number(b.id) - Number(a.id),
      )
      setGnomesFiltered(Name999To1)
    }
  }, [filter, gnomesArr])

  useEffect(() => {
    handleSort()
  }, [filter, handleSort])

  return gnomesFiltered
}
