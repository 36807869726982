import { css, styled } from '@mui/material'

interface ButtonProps {
  active?: boolean
}

export const TabIcon = styled('img')({
  width: '2rem',
  height: 'auto',
  flex: '0 0 auto',
})

export const TabRequest = styled('button')<ButtonProps>(
  ({ theme, active }) => css`
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border: none;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    flex: 0 0 auto;
    border-radius: 12px;
    padding: 1rem 2rem;
    background-color: transparent;
    color: ${theme.palette.font.neutral40};
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
      cursor: pointer;
      color: ${theme.palette.font.deepPurple};

      img {
        filter: brightness(0) saturate(100%) invert(16%) sepia(7%)
          saturate(2297%) hue-rotate(214deg) brightness(93%) contrast(94%);
      }
    }

    ${active &&
    css`
      background-color: ${theme.palette.font.deepPurple};
      color: ${theme.palette.font.main};

      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(18%)
          saturate(3103%) hue-rotate(280deg) brightness(121%) contrast(100%);
      }

      &:hover {
        color: ${theme.palette.font.main};
        cursor: default;

        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(18%)
            saturate(3103%) hue-rotate(280deg) brightness(121%) contrast(100%);
        }
      }
    `}
  `,
)

export const TabRequestContainer = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 2rem auto 0;
  `,
)

export const PaginationWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto 0;
  `,
)

export const GnomesList = styled('div')(
  () => css`
    width: 100%;
    max-width: 1140px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
    flex-flow: row wrap;
    gap: 16px;
    margin: 0 auto;
  `,
)

export const GnomeSortWrapper = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  `,
)

export const ContentContainer = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 40px;
  `,
)

export const TeamContainer = styled('div')(() => ({
  width: '100%',
  overflowX: 'hidden',
  padding: '0px 50px 50px 50px',
  boxSizing: 'border-box',
}))
