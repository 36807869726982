import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { commons } from '../../../images'
import { AddressShorten } from '../AddressShorten/AddressShorten'
import { useWalletContext } from '../../contexts/WalletContext'
import useGetBalance from '../../hooks/useGetBalance'
import { CommonButton } from '../CommonButton/CommonButton'
import { useAppContext } from '../../contexts/AppContext'
import { checkAndSwitchNetwork } from '../../helpers/wallet'
import {
  CommonTranslations,
  SnackbarTranslations,
  Translations,
} from '../../../config'

import { WalletDisconnect } from '../WalletDisconnect/WalletDisconnect'
import {
  AddressAvatar,
  WalletButtonContainer,
  WalletButtonWrapper,
} from './WalletButton.style'

export function WalletButton() {
  /* Translations */
  const { t } = useTranslation([Translations.COMMON, Translations.SNACKBAR])
  const { enqueueSnackbar } = useSnackbar()

  const { balance } = useGetBalance()
  const [showDisconnect, setShowDisconnect] = useState<boolean>(false)
  const handleModal = () => setShowDisconnect(!showDisconnect)

  /* Handle connect */
  const { address, browserProvider, activate } = useWalletContext()
  const { session, getSession } = useAppContext()
  const [loading, setLoading] = useState<boolean>(false)

  const onLogin = async () => {
    try {
      if (!address || !browserProvider) await activate()
      if (!address || !browserProvider) {
        enqueueSnackbar(t(SnackbarTranslations.COULD_NOT_CONNECT_TO_WALLET), {
          variant: 'error',
        })
        return
      }
      setLoading(true)
      await checkAndSwitchNetwork(browserProvider)
      await getSession(address, browserProvider)
    } finally {
      setLoading(false)
    }
  }

  if (!session)
    return (
      <CommonButton
        text={
          loading
            ? `${t(CommonTranslations.CONNECTING)}`
            : `${t(CommonTranslations.CONNECT)}`
        }
        clickHandler={onLogin}
        disabled={loading}
      />
    )

  return (
    <WalletButtonContainer>
      <WalletButtonWrapper onClick={handleModal}>
        <AddressAvatar src={commons.accountAvatar} />
        <AddressShorten address={address ?? ''} />
      </WalletButtonWrapper>

      <WalletDisconnect
        shown={showDisconnect}
        address={address ?? ''}
        balance={balance}
      />
    </WalletButtonContainer>
  )
}
