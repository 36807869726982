import { AttributesEntity } from '../../../commons/types'

interface Props {
  attrs: AttributesEntity[]
  attTofind: string
}

export function GetAttGnome({
  attrs,
  attTofind,
}: Props): string | number | undefined {
  const find = attrs?.find(att => att?.trait_type === attTofind)?.value

  return find ?? '-'
}
